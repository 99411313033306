




















import { Component, Vue } from 'vue-property-decorator';

import HeroAlt from '@/components/sections/HeroAlt.vue';
import InfoAlt from '@/components/sections/InfoAlt.vue';
import ImageAndText from '@/components/sections/ImageAndText.vue';

@Component({
  components: {
    HeroAlt,
    InfoAlt,
    ImageAndText
  },
  metaInfo: () => ({
    title: 'Biography'
  })
})
export default class Home extends Vue {
  public Childhood = [
    { indented: true, text: 'While growing up, Alton was placed into foster care under Marcy and Phil. For the time in foster care, Alton was well taken care of by his foster parents. However, Alton always longed for one feeling: truly belonging to someone. Although the foster care system provided a nurturing family to Alton, he always felt like it was a temporary family taking care of him while his real family couldn’t. When Alton slowly became older, he never had conversations with his parents about essential skills such as creating a savings account, registering to vote, and preparing for college. Without these skills and lectures, Alton was left in the dark and without a plan for his future. From this, Alton struggled as he entered adulthood.'},
  ]

  public CollegeLife = [
    { indented: true, text: 'Entering college was difficult for Alton as he was the first child in his family to enter it. He found it difficult to follow along in classes and often struggled with asking for help. This wasn’t the only problem he faced during college. Growing up in poverty and the absence of support from his family, Alton often found himself alone on campus. Other students had many belongings and parents to help them move into the dorm. In contrast, Alton had few belongings and no parents to support him. After reaching his tipping point, Alton left college and returned to his family in Stillwater.'}
  ]

  public RockBottom = [
    { indented: true, text: 'Upon returning to Stillwater, Alton went to his grandparents’ house but was promptly rejected by his grandfather to stay because he had not finished college. After being denied by his grandfather, Alton visited his mother’s house, which he was allowed in, but the place was too disheveled and infested with cockroaches for him to stay. With no other choice, Alton turned to the streets and the college campus in Stillwater.'},
    { indented: true, text: 'During the period of homelessness, Alton had to constantly worry about finding food and shelter each night. Since Alton lived in a college town, he regularly went to other people’s dorms and houses for food during mealtime. When he couldn’t get any food from the college campus, he would search the dumpsters of bakeries for expired goods. On the other hand, Alton would often live on his friend’s couch or find other places in the town to sleep for the night. Throughout the rest of his homelessness, Alton had to keep persevering to survive each day.'}
  ]

  public GradualRecovery = [
    { indented: true, text: 'After a few weeks of applying at several local fast-food, Alton was assigned a shift at a local McDonald’s to open the restaurant at 5:00 A.M. each day. During his shift, his tasks consisted of cooking the sausages, ham, and eggs. However, his job was to also keep the food hot and fresh strictly to McDonald’s guidelines. Consequently, during his time at McDonald’s, most of the food was thrown away. Incensed by the waste of food, Alton would save the expired food to help feed himself and his mother. After several months at McDonald’s, Alton resigned from his job in pursuit of a better job.' },
    { indented: true, text: 'At the same time, Alton met his future wife, Kristin, at the OSU Colvin Center. After a rough start, Alton and Kristin were in a strong relationship and often spent many hours together. Although the relationship was strong, Alton constantly worried about Kristin’s parents’ approval of their interracial relationship. However, Alton was reassured when they all met together at OSU’s 1989 Special Olympics Summer Games. At the game, Alton met Kristin’s parents, Dean and Cheryl, and her brother, Cole. Alton later said that he loved Cole even though he didn’t quite make the first impression on Kristin’s parents. After that, Alton would spend hours with Cole doing various sports and watching movies. As time passed, Kristin’s parents, Cheryl and Dean, slowly became more supportive and accepting of Alton. He shared interests and stories, helped them with tasks, and received help from them. Eventually, Alton became an integral part of Kristin’s family.' },
  ]

  public Fatherhood = [
    { indented: true, text: 'After Alton and Kristin married on August 12, 1995, Kristin became pregnant. Throughout Kristin’s pregnancy, Alton spent many hours researching fatherhood and asking anyone he could about his wife’s pregnancy. Outside of researching, Alton helped Kristin by preparing meals, attending appointments, and providing supplements. While Alton helped Kristin during her pregnancy, he also dedicated a room in their house to become a nursery and decorated and painted it. As they slowly neared the expected delivery date, Alton became more anxious and overwhelmed with constant uncertainty and doubts about himself. However, he refused to let it overwhelm him and kept moving forward.'},
    { indented: true, text: 'On May 28, 1998, Kristin went into labor and the couple welcomed their new baby boy into the world. Coming at eight pounds and measuring about twenty inches long, the baby was safely born with a full head of blond hair. Following the birth, Kristin and Alton decided to name the boy Kelton Thomas Carter. The first name comes from combining their names, while the last name comes from Alton’s grandfather. Together, Kristin and Alton raised Kelton for a few years before giving birth to another baby boy.'},
    { indented: true, text: 'At the Stillwater Medical Hospital, on May 8, 2000, Kristin went into labor for a second baby boy. However, it was different this time. As Kristin turned over slightly, monitors attached suddenly stopped reporting the vital signs of the baby. Immediately, this sent Alton into a shock as nurses rushed in to find signs of a heartbeat. Fortunately, it was an error as the nurses found the baby’s heartbeat in a couple of minutes. Soon after the incident, Kristin gave birth to Colin Dean Carter. Weighing eight pounds and measuring about twenty inches, Colin had brown skin and thick dark hair. With two sons in the family, Alton continued to pursue his dream of becoming a loving father to them.'},
  ]

  public Therapy = [
    { indented: true, text: 'Throughout his life, Alton struggled with depression and stress, and writing his first book exacerbated the problem by having him relive his past. Even with the support of his wife and children, it continued to become increasingly worse and began to manifest itself in his family. To save his marriage and his family, Alton began attending counseling at Stillwater Interfaith Counseling.'},
    { indented: true, text: 'During his counseling sessions, Alton faced many harsh truths about himself and his own behavior. While it exposed him and made him vulnerable, he learned more about his own feelings, which helped him accept them as he experienced them. For one of his treatment goals, Alton was instructed to accept his emotions as he perceived them. This was one of the many problems he struggled with because he would often make it worse by turning it into another emotion. For example, he would meet any conflicts between people with feelings of betrayal, which ultimately made him suffer because it put more anxiety on him. As he gradually recovered in counseling, he realized his status, wealth, and the type of people he spends time with doesn’t constitute to happiness. In contrast, he always judged his happiness based on those three criteria when he already had what it needed for happiness: a loving family, supportive friends, and a plan for himself. When Alton finished counseling, he stopped allowing his mistakes and regret from stagnating progress. With a renewed understanding of his life and goals, Alton continued to improve on what he does best: being the best father and husband for his family.'},
  ]

  
}
